// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-categories-archive-js": () => import("./../src/templates/categories/archive.js" /* webpackChunkName: "component---src-templates-categories-archive-js" */),
  "component---src-templates-post-blog-js": () => import("./../src/templates/post/blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */),
  "component---template-cache-tmp-10-rzeczy-mindfulness-js": () => import("./../.template-cache/tmp-10-rzeczy-mindfulness.js" /* webpackChunkName: "component---template-cache-tmp-10-rzeczy-mindfulness-js" */),
  "component---template-cache-tmp-a-jak-akceptacja-js": () => import("./../.template-cache/tmp-a-jak-akceptacja.js" /* webpackChunkName: "component---template-cache-tmp-a-jak-akceptacja-js" */),
  "component---template-cache-tmp-home-js": () => import("./../.template-cache/tmp-home.js" /* webpackChunkName: "component---template-cache-tmp-home-js" */),
  "component---template-cache-tmp-inner-development-goals-js": () => import("./../.template-cache/tmp-inner-development-goals.js" /* webpackChunkName: "component---template-cache-tmp-inner-development-goals-js" */),
  "component---template-cache-tmp-kawa-uwaznosc-i-ratowanie-swiata-js": () => import("./../.template-cache/tmp-kawa-uwaznosc-i-ratowanie-swiata.js" /* webpackChunkName: "component---template-cache-tmp-kawa-uwaznosc-i-ratowanie-swiata-js" */),
  "component---template-cache-tmp-multitasking-koszty-js": () => import("./../.template-cache/tmp-multitasking-koszty.js" /* webpackChunkName: "component---template-cache-tmp-multitasking-koszty-js" */),
  "component---template-cache-tmp-o-mindvue-js": () => import("./../.template-cache/tmp-o-mindvue.js" /* webpackChunkName: "component---template-cache-tmp-o-mindvue-js" */),
  "component---template-cache-tmp-o-traumie-medytacji-js": () => import("./../.template-cache/tmp-o-traumie-medytacji.js" /* webpackChunkName: "component---template-cache-tmp-o-traumie-medytacji-js" */),
  "component---template-cache-tmp-oferta-js": () => import("./../.template-cache/tmp-oferta.js" /* webpackChunkName: "component---template-cache-tmp-oferta-js" */),
  "component---template-cache-tmp-polityka-prywatnosci-js": () => import("./../.template-cache/tmp-polityka-prywatnosci.js" /* webpackChunkName: "component---template-cache-tmp-polityka-prywatnosci-js" */),
  "component---template-cache-tmp-uwaznosc-do-firmy-js": () => import("./../.template-cache/tmp-uwaznosc-do-firmy.js" /* webpackChunkName: "component---template-cache-tmp-uwaznosc-do-firmy-js" */),
  "component---template-cache-tmp-uwaznosc-relacje-z-jedzeniem-js": () => import("./../.template-cache/tmp-uwaznosc-relacje-z-jedzeniem.js" /* webpackChunkName: "component---template-cache-tmp-uwaznosc-relacje-z-jedzeniem-js" */)
}

